const burger = document.getElementById('burger');
const header = document.getElementById('header');

burger.addEventListener('click', () =>{
    header.classList.toggle('opened');
    window.scrollTo(0, 0)
})

window.addEventListener('scroll', () => {
    if(header.classList.contains('opened')){
        window.scrollTo(0, 0)
    }
})

if(document.getElementById('heroSliderTitles')){
    const heroSliderTitles = document.getElementById('heroSliderTitles').querySelectorAll('h1');
    const heroSliderImages = document.getElementById('heroSliderImages').querySelectorAll('img');

    if(heroSliderImages && heroSliderTitles){

        let heroSliderCpt = 1;
    
        setInterval(() => {
            heroSliderTitles.forEach((el) => {
                el.classList.remove('active');
            });
            heroSliderImages.forEach((el) => {
                el.classList.remove('active');
            });
            heroSliderTitles[heroSliderCpt].classList.add('active');
            heroSliderImages[heroSliderCpt].classList.add('active');
            
            heroSliderCpt++;
    
            if(heroSliderCpt > (heroSliderTitles.length - 1)){
                heroSliderCpt = 0;
            }
        }, 9100)
    }
}

$('#slickSliderJobs').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '.jobs__prev',
    nextArrow: '.jobs__next',
});

const map = document.getElementById('map');

if(map){

    //PIN 
    const pins = map.querySelectorAll('.pin');
    const gridEl = document.querySelectorAll('.listing__item');
    const contents = map.querySelectorAll('.map__right');
    const triggerGrid = document.getElementById('triggerGrid');
    const untriggerGrid = document.getElementById('untriggerGrid');
    const grid = document.getElementById('grid');

    pins.forEach((el, index) =>{
        el.style.zIndex = Math.round(el.offsetTop);
        el.addEventListener('click', () => {
            removeAll();
            $("html, body").animate({ scrollTop: 0 });
            contents[index].classList.add('active');
            el.classList.add('active');
            map.classList.remove('active');
        })

        contents[index].querySelector('.map__close').addEventListener('click', removeAll);
    });

    //GRID CLICK

    gridEl.forEach((el, index) =>{
        el.addEventListener('click', () => {
            removeAll();
            contents[index].classList.add('active');
            pins[index].classList.add('active');
            el.classList.add('active');
            map.classList.remove('active');
            
            if(window.innerHeight > 990){
                $("html, body").animate({ scrollTop: 0 });
            }
        })
    });

    function removeAll(){
        map.classList.add('active');
        pins.forEach((el) =>{
            el.classList.remove('active');
        });
        contents.forEach((el) =>{
            el.classList.remove('active');
        });
    }

    // FILTERS
    const filters = document.getElementById('filters').querySelectorAll('li[data-filter]');

    filters.forEach((el) => {
        el.addEventListener('click', () => {
            filters.forEach((_el) => {
                _el.classList.remove('active');
            })
            el.classList.add('active');
            filterAll(el.getAttribute('data-filter'));
        })
    })

    function filterAll(filter){
        if(filter != 'all'){
            pins.forEach((el) => {
                el.classList.remove('show');
                if(el.classList.contains(filter)){
                    el.classList.add('show');
                }
            })
        }
        else{
            pins.forEach((el) => {
                el.classList.add('show');
            })
        }
    }

    let scrollBreakPoint = window.innerHeight + 100;
    triggerGrid.addEventListener('click', () => {
        // grid.classList.add('active');
        //map.classList.remove('active');
        // map.classList.remove('show');
        pins.forEach((el) =>{
            el.classList.remove('active');
        });
        contents.forEach((el) =>{
            el.classList.remove('active');
        });
        map.classList.add('active');
        $("html, body").animate({ scrollTop: scrollBreakPoint });
    })
    
    untriggerGrid.addEventListener('click', () => {
        // grid.classList.remove('active');
        // map.classList.add('active');
        // map.classList.add('show');
        // pins.forEach((el) =>{
        //     el.classList.remove('active');
        // });
        // contents.forEach((el) =>{
        //     el.classList.remove('active');
        // });

        map.classList.add('active');
        $("html, body").animate({ scrollTop: 0 });
        pins.forEach((el) =>{
            el.classList.remove('active');
        });
        contents.forEach((el) =>{
            el.classList.remove('active');
        });
    })

    window.addEventListener('scroll', () => {
        if(window.scrollY > scrollBreakPoint - 50){
            untriggerGrid.classList.remove('active');
            triggerGrid.classList.add('active');
        } else {
            untriggerGrid.classList.add('active');
            triggerGrid.classList.remove('active');
        }
    })

}

const numbersToGrow = document.querySelectorAll('.footer__box strong');

function triggerNumbers(){
    numbersToGrow.forEach((el) => {
        el.style.display = "inline-block";
        el.style.width = el.getAttribute('data-size') + "px";
        let lastValue = parseFloat(el.getAttribute('data-value'));
        let currentValue = 0;
        let increment = lastValue / 35;
        let tofixed = 0;
        if(lastValue >= 10){
            tofixed = 0;
        } else{
            tofixed = 1;
        }
        el.innerHTML = 0;
        
        setTimeout(() => {
            let _int = setInterval(() => {
                if(currentValue < lastValue){
                    currentValue += increment;
                    el.innerHTML = currentValue.toFixed(tofixed);
                } else{
                    el.innerHTML = lastValue;
                    el.innerHTML = el.innerHTML.replace(".", ",");
                    clearInterval(_int);
                }
            }, 30);
        }, 500);
    })
}

if(numbersToGrow.length > 0){
    let triggered = false;
    setTimeout(() => {
        numbersToGrow.forEach((el) => {
            el.setAttribute('data-size', el.offsetWidth);
            el.innerHTML = el.innerHTML.replace(",", ".");
            el.innerHTML = el.innerHTML.replace(" ", "");
            el.setAttribute('data-value', el.innerHTML);
            el.innerHTML = "0";
        })
    }, 150);
    window.addEventListener('scroll', () => {
        if(isScrolledIntoView(document.querySelector('.footer__box')) && !triggered){
            triggerNumbers();
            triggered = true;
        }
    })
}

function isScrolledIntoView(elem)
{
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}




//scroll

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true; } 
  }));
} catch(e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function disableScroll() {
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll() {
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt); 
  window.removeEventListener('touchmove', preventDefault, wheelOpt);
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}